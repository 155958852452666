.Notification {
  width: 300px;
  background-color: var(--color-background);
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  border-radius: 4px;
  padding: 15px 10px;
  transition: all 0.25s;
  margin-bottom: 10px;
}

.Notification:hover {
  background-color: var(--color-primary);
  color: var(--color-background);
  cursor: pointer;
}

.Notification:last-child {
  margin-bottom: 0;
}

.NotificationOpen {
  animation: slideIn 0.3s;
}

.NotificationClose {
  animation: slideOut 0.3s;
  transform: translateX(600px);
}

@keyframes slideIn {
  from {
    transform: translateX(600px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(600px);
  }
}