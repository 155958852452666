.Spinner,
.Spinner:before,
.Spinner:after {
  background: var(--color-primary);
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.Spinner {
  color: var(--color-primary);
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.Spinner:before,
.Spinner:after {
  position: absolute;
  top: 0;
  content: '';
}

.Spinner:before {
  left: -1.5em;
  animation-delay: -0.32s;
}

.Spinner:after {
  left: 1.5em;
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.SpinnerWrapper {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}