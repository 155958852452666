.text {
  color: var(--color-primary);
  margin-bottom: 15px;
}

.text a,
.text span {
  color: var(--color-accent);
}

.separator {
  margin: 30px 0;
  border: 1px solid var(--color-primary);
}
