.ModalForm {
  background-color: var(--color-background);
  color: var(--color-primary);
  border-radius: 6px;
  width: 95%;
  position: relative;
  padding: 50px 50px;
}

.ModalFormIcon {
  width: 40px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.ModalFormIcon:hover {
  cursor: pointer;
}

@media (min-width: 430px) {
  .ModalForm {
    width: 90%;
  }
}

@media (min-width: 800px) {
  .ModalForm {
    width: 70%;
  }
}

@media (min-width: 1000px) {
  .ModalForm {
    width: 60%;
  }
}

@media (min-width: 1400px) {
  .ModalForm {
    width: 40%;
  }
}