.AppCard {
  margin-bottom: 20px;
}

.AppCard h3 {
  color: var(--color-accent);
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.AppHeader:hover {
  cursor: pointer;
}

.AppIcon {
  width: 35px;
  height: 35px;
  margin-right: 0.5em;
}

.AppCardDetails {
  text-transform: uppercase;
}

.AppCardDetails h5 {
  font-size: 1em;
  font-weight: 500;
  color: var(--color-primary);
}

.AppCardDetails span {
  color: var(--color-accent);
  font-weight: 400;
  font-size: 0.8em;
  opacity: 1;
}

.Apps {
  display: flex;
  flex-direction: column;
  padding: 2px;
  border-radius: 4px;
  transition: all 0.1s;
}

.Apps a {
  transition: all 0.25s;
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}

.Apps a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.CustomIcon {
  width: 90%;
  height: 90%;
  margin-top: 2px;
  margin-left: 2px;
  object-fit: contain;
}
