.ActionIcons {
  display: flex;
}

.ActionIcons svg {
  width: 20px;
}

.ActionIcons svg:hover {
  cursor: pointer;
}
