.AppGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 430px) {
  .AppGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 670px) {
  .AppGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 900px) {
  .AppGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}
