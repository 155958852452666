.SettingsButton {
  width: 35px;
  height: 35px;
  background-color: var(--color-accent);
  border-radius: 50%;
  position: fixed;
  bottom: var(--spacing-ui);
  left: var(--spacing-ui);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.25;
  transition: all 0.3s;
  visibility: hidden;
}

.SettingsButton:hover {
  cursor: pointer;
  opacity: 1;
}

@media (min-width: 769px) {
  .SettingsButton {
    visibility: visible;
  }
}

.HomeSpace {
  height: 20px;
}
