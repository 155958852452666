.Modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ModalClose {
  background-color: rgba(0, 0, 0, 0);
  visibility: hidden;
}

.ModalOpen {
  background-color: rgba(0, 0, 0, 0.7);
  visibility: visible;
}